import { History, Location } from 'history';
import { getEntitiesFromSearchParams } from './entity-helpers';

export const full_hierarchy: string[] = ['company', 'sector', 'line', 'workstation'];
export const navigation_hierarchy: string[] = ['company', 'sector', 'line', 'workstation'];

export function handleBackClick(location: Location, history: History): void {
	const search_params = new URLSearchParams(location.search);
	const entities = getEntitiesFromSearchParams(search_params);

	if (entities.length > 0) {
		const new_search_params = new URLSearchParams(location.search);
		new_search_params.delete('offset');
		const lastEntity = entities[entities.length - 1];

		const index = full_hierarchy.indexOf(lastEntity.entity);
		for (let i = index; i < full_hierarchy.length; i++) {
			new_search_params.delete(full_hierarchy[i]);
			new_search_params.delete(`${full_hierarchy[i]}_name`);
		}

		history.push({
			pathname: location.pathname,
			search: `?${new_search_params.toString()}`
		});
	} else {
		history.push('/ehs/dashboard'); // Redirecionar depois para a rota correta do dashboard
	}
}
