import { BaseService } from './base-service';
import { Mosaic } from '@/core/domain/mosaic';
import { SectorMosaicMapper } from '@/core/mapper/sector-mosaic';
import { FindAllSectorsWorstScoreRequestDTO, GetSectorsDTO, SectorDTO } from '@/core/dto/sector';

export class SectorService extends BaseService<any> {
	private static instance: SectorService;

	constructor(public readonly basePath: string = '/sector') {
		super();
	}

	public static getInstance(): SectorService {
		if (!SectorService.instance) {
			SectorService.instance = new SectorService();
		}
		return SectorService.instance;
	}

	public async index(params: GetSectorsDTO): Promise<SectorDTO[]> {
		const { organization_id, company_id } = params;
		const { data } = await this.getInstance().get<SectorDTO[]>(`/sector/${organization_id}/${company_id}`);
		return data;
	}

	public async findAllWorstScore(params: FindAllSectorsWorstScoreRequestDTO): Promise<Mosaic> {
		const url = this.basePath + '/worst-score';
		const { data } = await this.getInstance().get(url, { params });
		return new SectorMosaicMapper(data).toDomain();
	}
}
