import * as React from 'react';

interface RestrictedProps extends React.SVGProps<SVGElement> {
	$count?: number;
}

export function Restricted({ $count, ...props }: RestrictedProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="54"
			height="32"
			fill="none"
			viewBox="0 0 54 32"
			{...props}
			className={`count-${$count}`}
			ref={undefined}
		>
			<path
				fill="#152770"
				d="M25.47.906a3 3 0 0 1 3.06 0L49.644 13.42c1.96 1.162 1.96 4 0 5.162L28.529 31.094a3 3 0 0 1-3.058 0L4.355 18.58c-1.96-1.162-1.96-4 0-5.162z"
			></path>
			{$count !== undefined && (
				<text x="27" y="20" fill="white" textAnchor="middle" fontSize="14" fontWeight={600}>
					{$count}
				</text>
			)}
		</svg>
	);
}
