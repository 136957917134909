import Api from '@/services/api';
import type { AxiosInstance } from 'axios';
import type { HealthMatrix } from '@/core/types/HealthMatrix';
import type { GetWorkMetricsRequestDTO, GetWorkMetricsResponseDTO } from '@/core/dto/appointment/get-work-metrics';

export class BaseService {
	constructor(private readonly api: AxiosInstance = Api) {}

	getInstance(): AxiosInstance {
		return this.api;
	}
}

export class AppointmentService extends BaseService {
	private static instance: AppointmentService;

	private constructor(public readonly basePath: string = '/appointment') {
		super();
	}

	public static getInstance(): AppointmentService {
		if (!AppointmentService.instance) {
			AppointmentService.instance = new AppointmentService();
		}
		return AppointmentService.instance;
	}

	public async getWorkMetrics(params: GetWorkMetricsRequestDTO): Promise<HealthMatrix[]> {
		let url = this.basePath + `/work-metrics?`;
		const { data } = await this.getInstance().get<GetWorkMetricsResponseDTO>(url, { params });
		return data;
	}
}
