import { BaseService } from './base-service';
import { Mosaic } from '@/core/domain/mosaic';
import { LineMosaicMapper } from '@/core/mapper/line-mosaic';
import { FindAllLinesWorstScoreRequestDTO, GetLinesRequestDTO, LineDTO } from '@/core/dto/line';

export class LineService extends BaseService<any> {
	private static instance: LineService;

	constructor(public readonly basePath: string = '/line') {
		super();
	}

	public static getInstance(): LineService {
		if (!LineService.instance) {
			LineService.instance = new LineService();
		}
		return LineService.instance;
	}

	public async index(params: GetLinesRequestDTO): Promise<LineDTO[]> {
		const { organization_id, company_id, sector_id } = params;
		const { data } = await this.getInstance().get<LineDTO[]>(`/line/${organization_id}/${company_id}/${sector_id}`);
		return data;
	}

	public async findAllWorstScore(params: FindAllLinesWorstScoreRequestDTO): Promise<Mosaic> {
		const url = this.basePath + '/worst-score';
		const { data } = await this.getInstance().get(url, { params });
		return new LineMosaicMapper(data).toDomain();
	}
}
