import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { ChecklistService } from '@/infra/services/checklist';

interface GetChecklistHistoryParams {
	organization_id?: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	workstation_id?: string;
	start_date?: string;
	end_date?: string;
	granularity?: 'day' | 'week' | 'month' | 'quarter' | 'semester' | 'year';
}

const Service = ChecklistService.getInstance();

export function useGetChecklistHistory(params: GetChecklistHistoryParams) {
	return useQuery([QUERY_KEYS.GET_CHECKLIST_HISTORY, params], () => Service.getChecklistHistory(params), {
		enabled: !!params.organization_id && !!params.start_date && !!params.end_date
	});
}
