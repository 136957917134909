import React from 'react';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { I18n } from '@aws-amplify/core';
import { useLocation } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import 'moment/locale/pt-br';

import { useApplicationContext } from '@/context/application';
import { useGetChecklistHistory } from '@/views/Dashboard/hooks/useGetChecklistHistory';

import { ChartContent } from './styles';
import { SkeletonChart } from './Skeleton';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const NAVIGATOR_LANGUAGE = window.navigator.language;
const PT_BR = 'pt-BR';
const DAY = 'day';
const MONTH = 'month';
const QUARTER = 'quarter';
const SEMESTER = 'semester';

export function HistoryChart() {
	const location = useLocation();
	const { organization } = useApplicationContext();
	const searchParams = new URLSearchParams(location.search);
	const granularity = (searchParams.get('granularity') as any) || 'month';

	const defaultStartDate = moment().startOf('year').format('YYYY-MM-DD');
	const defaultEndDate = moment().endOf('year').format('YYYY-MM-DD');

	const { data: historyData, isLoading } = useGetChecklistHistory({
		organization_id: organization?.id,
		line_id: searchParams.get('line') || '',
		sector_id: searchParams.get('sector') || '',
		company_id: searchParams.get('company') || '',
		workstation_id: searchParams.get('workstation') || '',
		end_date: searchParams.get('end_date') || defaultEndDate,
		start_date: searchParams.get('start_date') || defaultStartDate,
		granularity
	});

	moment.locale('pt-br');

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		animation: {
			duration: 0
		},
		scales: {
			x: {
				stacked: true,
				grid: {
					display: false
				},
				border: {
					color: '#262626'
				}
			},
			y: {
				stacked: true,
				grid: {
					color: 'rgba(38, 38, 38, 0.2)'
				},
				beginAtZero: true,
				ticks: {
					precision: 0,
					callback: (value: number | string) => {
						if (Number.isInteger(Number(value))) {
							return value;
						}
						return '';
					},
					stepSize: 1
				}
			}
		},
		plugins: {
			legend: {
				display: false
			},
			datalabels: {
				color: '#000000',
				font: {
					weight: 'bold' as const,
					size: 12
				},
				formatter: (value: number) => value || '',
				display: (context: any) => {
					const dataset = context.dataset;
					const value = dataset.data[context.dataIndex];
					const meta = context.chart.getDatasetMeta(context.datasetIndex);
					const height = meta.data[context.dataIndex].height;
					const width = meta.data[context.dataIndex].width;
					return value > 0 && height > 15 && width > 15;
				}
			}
		},
		barPercentage: granularity === 'day' ? 0.9 : 0.7,
		categoryPercentage: granularity === 'day' ? 0.9 : 1,
		maxBarThickness: granularity === 'day' ? undefined : 60
	};

	function getLabelFormat(dateGroup: string) {
		if (NAVIGATOR_LANGUAGE === PT_BR) {
			if (granularity === MONTH) {
				const [monthAbbr, year] = dateGroup.split(' ');
				const originalLocale = moment.locale();
				moment.locale('en');
				const monthName = moment(monthAbbr, 'MMM').locale('pt-br').format('MMMM');
				moment.locale(originalLocale);
				return `${monthName.charAt(0).toUpperCase() + monthName.slice(1)} ${year}`;
			}
			if (granularity === QUARTER) {
				const [quarter, year] = dateGroup.split(' ');
				return `${quarter.replace('Q', '')}º Trimestre ${year}`;
			}
			if (granularity === SEMESTER) {
				const [semester, year] = dateGroup.split(' ');
				return `${semester.replace('H', '')}º Semestre ${year}`;
			}
			if (granularity === DAY) {
				const [day, month, year] = dateGroup.split(' ');
				return `${day} ${I18n.get(month)} ${year}`;
			}
		}
		return dateGroup;
	}

	const formattedLabels = historyData?.map((item: any) => getLabelFormat(item.date_group)) || [];

	const data = {
		labels: formattedLabels,
		datasets: [
			{
				label: I18n.get('Low risk'),
				data: historyData?.map((item: any) => item.low_risk) || [],
				backgroundColor: 'rgba(44, 200, 82, 0.7)',
				borderColor: 'rgba(44, 200, 82, 1)',
				borderWidth: 2
			},
			{
				label: I18n.get('Medium risk'),
				data: historyData?.map((item: any) => item.medium_risk) || [],
				backgroundColor: 'rgba(248, 214, 39, 0.7)',
				borderColor: 'rgba(248, 214, 39, 1)',
				borderWidth: 2
			},
			{
				label: I18n.get('High risk'),
				data: historyData?.map((item: any) => item.high_risk) || [],
				backgroundColor: 'rgba(231, 65, 80, 0.7)',
				borderColor: 'rgba(231, 65, 80, 1)',
				borderWidth: 2
			}
		]
	};

	return <ChartContent>{isLoading ? <SkeletonChart /> : <Bar options={options} data={data} />}</ChartContent>;
}
