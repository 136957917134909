import React from 'react';
import { Tooltip } from 'antd';
import { RISK_COLORS_INDEX } from '../../../constants';
import * as Box from './Box';
import * as S from '../styles';

interface Item {
	id: string;
	name: string;
	worst_score: number;
	women_count: number;
	pcd_count: number;
	restricted_count: number;
}

interface MosaicComponentProps {
	item: Item;
	entity: string;
	onClick: (id: string, entity: string, name: string) => void;
}

const MAX_CHARACTER_NAME = 50;

export function MosaicComponent({ item, entity, onClick }: Readonly<MosaicComponentProps>) {
	function handleOnClick(): void {
		onClick(item.id, entity, item.name);
	}

	function handleOnFormatName(name: string): string {
		return name.length > MAX_CHARACTER_NAME ? `${name.substring(0, MAX_CHARACTER_NAME)}...` : name;
	}

	const displayName = handleOnFormatName(item.name);

	return (
		<S.MosaicCard key={item.id} onClick={handleOnClick} $color={RISK_COLORS_INDEX[item.worst_score]}>
			<Tooltip title={item.name}>
				<span>{displayName}</span>
			</Tooltip>
			<div className="legend">
				<Box.Women $count={item.women_count} />
				<Box.Pwd $count={item.pcd_count} />
				<Box.Restricted $count={item.restricted_count} />
			</div>
		</S.MosaicCard>
	);
}
