import React from 'react';
import { Row, Col, Skeleton } from 'antd';

export function GridSkeleton() {
	return (
		<Row gutter={[16, 16]}>
			{Array.from({ length: 16 }).map((_, index) => (
				<Col key={index} span={6}>
					<Skeleton.Input
						active
						block
						style={{
							height: 105
						}}
					/>
				</Col>
			))}
		</Row>
	);
}
