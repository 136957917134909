import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { RISK_COLORS } from '@/views/Dashboard/constants';
import { Title } from '@/components/Typography';
import * as Box from '../Box';
import * as S from './styles';

export function Legend() {
	return (
		<Row justify="center">
			<Col span={23}>
				<Row justify="space-between">
					<Col xxl={12} xl={15} lg={24} sm={24}>
						<S.LegendWrapper>
							<S.LegendRiskWrapper>
								<Title level={5} style={{ width: '340px' }}>
									{I18n.get('Highest risk found')}:
								</Title>
								<S.LegendItems>
									<S.LegendItem className="text-400">
										<S.ColorBox $color={RISK_COLORS.LOW_RISK} />
										<Title level={5}>Low risk</Title>
									</S.LegendItem>
									<S.LegendItem className="text-400">
										<S.ColorBox $color={RISK_COLORS.MEDIUM_RISK} />
										<Title level={5}>Medium risk</Title>
									</S.LegendItem>
									<S.LegendItem className="text-400">
										<S.ColorBox $color={RISK_COLORS.HIGH_RISK} />
										<Title level={5}>High risk</Title>
									</S.LegendItem>
								</S.LegendItems>
							</S.LegendRiskWrapper>
						</S.LegendWrapper>
					</Col>
					<Col xxl={9} xl={10} lg={14} sm={24}>
						<S.LegendWrapper>
							<S.LegendItems>
								<S.LegendItem>
									<Title level={5}>{I18n.get('Women')}:</Title>
									<Box.Women />
								</S.LegendItem>
								<S.LegendItem>
									<Title level={5}>{I18n.get('PWD')}:</Title>
									<Box.Pwd />
								</S.LegendItem>
								<S.LegendItem>
									<Title level={5}>{I18n.get('Restricted')}:</Title>
									<Box.Restricted />
								</S.LegendItem>
							</S.LegendItems>
						</S.LegendWrapper>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
