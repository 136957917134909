import React, { ReactElement, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form, Select, SelectProps, Skeleton } from 'antd';

import { useApplicationContext } from '@/context/application';
import { useCreateLine } from '@/hooks/useLineMutations';
import { DropdownRender } from '../../DropdownRender';

import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import type { DefaultOptionType } from 'antd/lib/select';
import type { NamePath } from 'antd/lib/form/interface';
import type { CreateLineDTO, Line } from '@/types';
import type { LineDTO } from '@/core/dto/line';

const { useFormInstance } = Form;

type LineSelectProps = {
	size?: SizeType;
	lineId?: string;
	loading: boolean;
	sectorId: string;
	disabled: boolean;
	fieldName: NamePath;
	allowCreation?: boolean;
	lines: Line[] | LineDTO[] | undefined;
};

type OptionType = { label: string; value: string } | undefined;

export function LineSelect({
	lines,
	lineId,
	loading,
	disabled,
	sectorId,
	fieldName,
	size = 'middle',
	allowCreation = true,
	...props
}: LineSelectProps & SelectProps): JSX.Element {
	const [lineName, setLineName] = useState('');

	const { organization, company } = useApplicationContext();
	const { validateFields, setFieldValue } = useFormInstance();
	const { mutateAsync: createLine, isLoading } = useCreateLine();

	async function onCreateLine() {
		const body: CreateLineDTO = {
			organization_id: organization?.id as string,
			company_id: company?.id as string,
			sector_id: sectorId,
			name: lineName
		};

		const line = await createLine({ body });
		const lineId = line?.id;

		if (lineId) {
			handleChange(lineId);
		}

		setLineName('');
	}

	async function handleChange(id: string) {
		await validateFields();
		setFieldValue(fieldName, id);
	}

	function handleLineNameChange(event: React.ChangeEvent<HTMLInputElement>) {
		const name = event.target.value;
		setLineName(name);
	}

	function filterOption(option: OptionType | DefaultOptionType, input: string): boolean {
		return (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase());
	}

	function showDropdownRender(menu: ReactElement): JSX.Element {
		return (
			<DropdownRender
				menu={menu}
				name={lineName}
				loading={isLoading}
				allowCreation={allowCreation}
				onClick={onCreateLine}
				onChange={handleLineNameChange}
			/>
		);
	}

	if (loading) {
		return <Skeleton.Input block active size="small" />;
	}

	return (
		<Select
			{...props}
			allowClear
			showSearch
			size={size}
			value={lineId}
			disabled={disabled}
			placeholder={I18n.get('Line')}
			dropdownRender={(menu) => showDropdownRender(menu)}
			filterOption={(input, option) => filterOption(option, input)}
			options={lines?.map(({ id, name }) => ({ label: name, value: id }))}
		/>
	);
}
