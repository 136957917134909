import { BaseService } from './base-service';
import { Company } from '@/core/domain/company';
import { Mosaic } from '@/core/domain/mosaic';
import { CompanyMosaicMapper } from '@/core/mapper/company-mosaic';
import { CompanyDTO, CompanyMosaicDTO, GetCompaniesRequestDTO, FindAllWorstScoreRequestDTO } from '@/core/dto/company';

export class CompanyService extends BaseService<any> {
	private static instance: CompanyService;

	constructor(public readonly basePath: string = '/company') {
		super();
	}

	public static getInstance(): CompanyService {
		if (!CompanyService.instance) {
			CompanyService.instance = new CompanyService();
		}
		return CompanyService.instance;
	}

	public async index(params: GetCompaniesRequestDTO): Promise<Company[]> {
		const { organization_id } = params;
		const { data } = await this.getInstance().get<CompanyDTO[]>(`/company/user_list/${organization_id}`);
		return data;
	}

	public async findAllWorstScore(params: FindAllWorstScoreRequestDTO): Promise<Mosaic> {
		const url = this.basePath + '/worst-score';
		const { data } = await this.getInstance().get<CompanyMosaicDTO>(url, { params });
		return new CompanyMosaicMapper(data).toDomain();
	}
}
