import { BaseService } from './base-service';
import { Mosaic } from '@/core/domain/mosaic';
import { WorkstationMosaicMapper } from '@/core/mapper/workstation-mosaic';
import {
	FindAllWorkstationsWorstScoreRequestDTO,
	GetWorkstationsRequestDTO,
	WorkstationDTO
} from '@/core/dto/workstation';

export class WorkstationService extends BaseService<any> {
	private static instance: WorkstationService;

	constructor(public readonly basePath: string = '/workstation') {
		super();
	}

	public static getInstance(): WorkstationService {
		if (!WorkstationService.instance) {
			WorkstationService.instance = new WorkstationService();
		}
		return WorkstationService.instance;
	}

	public async index(params: GetWorkstationsRequestDTO): Promise<WorkstationDTO[]> {
		const { organization_id, company_id, line_id } = params;
		const { data } = await this.getInstance().get<WorkstationDTO[]>(
			`/workstation/${organization_id}/${company_id}/${line_id}`
		);
		return data;
	}

	async findAllWorstScore(params: FindAllWorkstationsWorstScoreRequestDTO): Promise<Mosaic> {
		const url = this.basePath + '/worst-score';
		const { data } = await this.getInstance().get(url, { params });
		return new WorkstationMosaicMapper(data).toDomain();
	}
}
