import React from 'react';
import effortIcon from '@/assets/img/filters/effort.svg';
import reactionIcon from '@/assets/img/filters/reaction.svg';
import vibrationIcon from '@/assets/img/filters/vibration.svg';
import beats_againstIcon from '@/assets/img/filters/beats_against.svg';
import percussionIcon from '@/assets/img/filters/percursion.svg';
import upper_armIcon from '@/assets/img/filters/upper_arm.svg';
import handIcon from '@/assets/img/filters/hand.svg';
import trunkIcon from '@/assets/img/filters/trunk.svg';
import neckIcon from '@/assets/img/filters/neck.svg';
import hipIcon from '@/assets/img/filters/hip.svg';
import { CardImage } from './styles';

const icons = {
	effort: effortIcon,
	reaction: reactionIcon,
	vibration: vibrationIcon,
	beats_against: beats_againstIcon,
	percussion: percussionIcon,
	upper_arm: upper_armIcon,
	hand: handIcon,
	trunk: trunkIcon,
	neck: neckIcon,
	hip: hipIcon
};

interface CardIconProps {
	name: string;
}

export function CardIcon({ name }: CardIconProps) {
	return (
		<CardImage>
			<img src={icons[name as keyof typeof icons]} alt="filter icon" />
		</CardImage>
	);
}
