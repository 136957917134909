import React from 'react';
import { I18n } from '@aws-amplify/core';
import { RangePickerProps } from 'antd/es/date-picker';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import { FORM_KEYS } from './constants';
import { CustomFormItem, CustomRangePicker, PeriodContainer, CustomLabel } from '../styles';

interface ReferencePeriodProps {
	formName: string[];
}

export function Period({ formName }: Readonly<ReferencePeriodProps>) {
	const history = useHistory();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const fieldName = [...formName, FORM_KEYS.REFERENCE_PERIOD];

	const currentYearStart = moment().startOf('year');
	const today = moment().endOf('day');

	const defaultStart = searchParams.get('start_date') || currentYearStart.format('YYYY-MM-DD');
	const defaultEnd = searchParams.get('end_date') || today.format('YYYY-MM-DD');

	const defaultValue = [moment(defaultStart), moment(defaultEnd)];

	function handleOnChange(dates: RangePickerProps['value']): void {
		const params = new URLSearchParams(location.search);

		if (dates && dates[0] && dates[1]) {
			const [startDate, endDate] = dates;
			params.set('start_date', startDate.format('YYYY-MM-DD'));
			params.set('end_date', endDate.format('YYYY-MM-DD'));
		} else {
			params.delete('start_date');
			params.delete('end_date');
		}

		history.replace({
			pathname: location.pathname,
			search: params.toString()
		});
	}

	return (
		<PeriodContainer>
			<CustomLabel>{I18n.get('Select period')}</CustomLabel>
			<CustomFormItem name={fieldName} initialValue={defaultValue}>
				<CustomRangePicker
					onChange={handleOnChange}
					format={moment.localeData().longDateFormat('L')}
					disabledDate={(current) => current && current > today}
				/>
			</CustomFormItem>
		</PeriodContainer>
	);
}
