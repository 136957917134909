import React, { MouseEvent, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Card, CardTitle, SplittedContent } from './styles';
import { CardIcon } from './CardIcon';
import { Filter } from './types';

export type FilterCardProps = {
	name: string;
	title: string;
	type: 'single' | 'bodyPart' | 'dualBodyPart' | 'lowerMember';
	filter?: Filter;
	onChangeFilter: (filter: Filter) => void;
};

export function FilterCard({ name, title, type, filter, onChangeFilter }: FilterCardProps) {
	const [side, setSide] = useState<'right' | 'left'>();
	const [step, setStep] = useState<'default' | 'side' | 'property' | 'movement'>('default');

	function handleClick() {
		if (type === 'single') {
			onChangeFilter(name as Filter);
		} else if (type === 'dualBodyPart') {
			setStep('side');
		} else if (type === 'bodyPart') {
			setStep('property');
		} else if (type === 'lowerMember') {
			setStep('movement');
		}
	}

	function handlePropertyClick(property: 'frequency' | 'duration', e: MouseEvent) {
		e.stopPropagation();
		if (type === 'bodyPart') {
			onChangeFilter(`${property}_${name}` as Filter);
		} else {
			onChangeFilter(`${property}_${side}_${name}` as Filter);
		}
		setStep('default');
	}

	function handleSideClick(side: 'right' | 'left', e: MouseEvent) {
		e.stopPropagation();
		setSide(side);
		setStep('property');
	}

	function handleMovementClick(movement: 'squat' | 'displacement', e: MouseEvent) {
		e.stopPropagation();
		onChangeFilter(`${movement}_${name}` as Filter);
		setStep('default');
	}

	return (
		<Card key={name} selected={!!filter?.includes(name)} onClick={handleClick}>
			{step === 'default' && (
				<>
					<CardIcon name={name} />
				</>
			)}

			{step === 'side' && (
				<SplittedContent>
					<div onClick={(e) => handleSideClick('left', e)}>
						<span>{I18n.get('Left')}</span>
					</div>
					<div onClick={(e) => handleSideClick('right', e)}>
						<span>{I18n.get('Right')}</span>
					</div>
				</SplittedContent>
			)}

			{step === 'property' && (
				<SplittedContent>
					<div onClick={(e) => handlePropertyClick('frequency', e)}>
						<span>{I18n.get('Frequency')}</span>
					</div>
					<div onClick={(e) => handlePropertyClick('duration', e)}>
						<span>{I18n.get('Duration')}</span>
					</div>
				</SplittedContent>
			)}

			{step === 'movement' && (
				<SplittedContent>
					<div onClick={(e) => handleMovementClick('squat', e)}>
						<span>{I18n.get('Squat')}</span>
					</div>
					<div onClick={(e) => handleMovementClick('displacement', e)}>
						<span>{I18n.get('Displacement')}</span>
					</div>
				</SplittedContent>
			)}
			<CardTitle>{title}</CardTitle>
		</Card>
	);
}
