import React from 'react';
import { Radio } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory, useLocation } from 'react-router-dom';

import { GRANULARITY } from './constants';
import { RadioChangeEvent } from 'antd/es/radio';
import { CustomFormItem, RadioGroup } from '../styles';

interface GranularityProps {
	readonly formName: string[];
}

export function Granularity({ formName }: GranularityProps) {
	const history = useHistory();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const defaultValue = searchParams.get('granularity') || GRANULARITY.MONTH;

	function handleGranularityChange(e: RadioChangeEvent) {
		const params = new URLSearchParams(location.search);
		params.set('granularity', e.target.value);
		history.replace({
			pathname: location.pathname,
			search: params.toString()
		});
	}

	return (
		<CustomFormItem name={[...formName, 'granularity']} initialValue={defaultValue}>
			<RadioGroup onChange={handleGranularityChange}>
				<Radio.Button value={GRANULARITY.DAY}>{I18n.get('Day')}</Radio.Button>
				<Radio.Button value={GRANULARITY.WEEK}>{I18n.get('Week')}</Radio.Button>
				<Radio.Button value={GRANULARITY.MONTH}>{I18n.get('Month')}</Radio.Button>
				<Radio.Button value={GRANULARITY.QUARTER}>{I18n.get('Quarter')}</Radio.Button>
				<Radio.Button value={GRANULARITY.SEMESTER}>{I18n.get('Semester')}</Radio.Button>
				<Radio.Button value={GRANULARITY.YEAR}>{I18n.get('Year')}</Radio.Button>
			</RadioGroup>
		</CustomFormItem>
	);
}
