import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { LineService } from '@/infra/services/line';

export interface GetLinesWorstScoreParams {
	organization_id: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
	limit: number;
	offset: number;
	filter?: string;
}

const Service = LineService.getInstance();

export const useGetLinesWorstScore = (params: GetLinesWorstScoreParams) => {
	const { company_id, organization_id, limit, offset, sector_id, filter, line_id } = params;
	return useQuery(
		[
			QUERY_KEYS.GET_LINES_WORST_SCORE,
			{
				organization_id,
				company_id,
				sector_id,
				limit,
				offset,
				filter
			}
		],
		() =>
			Service.findAllWorstScore({
				organization_id,
				company_id: company_id!,
				sector_id: sector_id!,
				limit,
				offset,
				filter
			}),
		{
			enabled: !!organization_id && !!company_id && !!sector_id && !line_id,
			retry: 1
		}
	);
};
