import { Mapper } from './base-mapper';
import { Mosaic, MosaicItem, MosaicEntity } from '@/core/domain/mosaic';
import { WorkstationMosaicDTO, WorkstationMosaicItemDTO } from '@/core/dto/workstation';

export class WorkstationMosaicMapper extends Mapper<Mosaic> {
	private readonly rows: WorkstationMosaicItemDTO[];
	private readonly entity: MosaicEntity;
	private readonly limit: number;
	private readonly offset: number;
	private readonly total: number;

	constructor({ rows, limit, offset, total }: WorkstationMosaicDTO) {
		super();
		this.rows = rows;
		this.entity = 'workstation';
		this.limit = limit;
		this.offset = offset;
		this.total = total;
	}

	toTransformItem(value: MosaicItem): MosaicItem {
		return {
			id: value.id,
			name: value.name,
			worst_score: value.worst_score,
			pcd_count: value.pcd_count,
			restricted_count: value.restricted_count,
			women_count: value.women_count
		};
	}

	toDomain(): Mosaic {
		return {
			rows: this.rows.map(this.toTransformItem.bind(this)),
			limit: this.limit,
			offset: this.offset,
			total: this.total,
			entity: 'workstation'
		};
	}
}
