import React from 'react';
import moment from 'moment';
import ExcelJS from 'exceljs';
import { message } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DownloadOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useApplicationContext } from '@/context/application';
import { useGetChecklistHistory } from '@/views/Dashboard/hooks/useGetChecklistHistory';
import { ButtonWrapper, CustomButton } from '../styles';

export function DownloadCSV() {
	const location = useLocation();
	const { organization } = useApplicationContext();
	const searchParams = new URLSearchParams(location.search);

	const defaultStartDate = moment().startOf('year').format('YYYY-MM-DD');
	const defaultEndDate = moment().endOf('year').format('YYYY-MM-DD');

	const { data: historyData } = useGetChecklistHistory({
		organization_id: organization?.id,
		line_id: searchParams.get('line') || '',
		sector_id: searchParams.get('sector') || '',
		company_id: searchParams.get('company') || '',
		workstation_id: searchParams.get('workstation') || '',
		end_date: searchParams.get('end_date') || defaultEndDate,
		start_date: searchParams.get('start_date') || defaultStartDate,
		granularity: (searchParams.get('granularity') as any) || 'month'
	});

	const columns = [
		{ key: 'granularity', width: 15, header: I18n.get('Granularity') },
		{ key: 'date_group', width: 15, header: I18n.get('Period') },
		{ key: 'low_risk', width: 15, header: I18n.get('Low risk') },
		{ key: 'medium_risk', width: 15, header: I18n.get('Medium risk') },
		{ key: 'high_risk', width: 15, header: I18n.get('High risk') },
		{ key: 'organization_name', width: 15, header: I18n.get('Organization') },
		{ key: 'company_name', width: 15, header: I18n.get('Company') },
		{ key: 'sector_name', width: 15, header: I18n.get('Sector') },
		{ key: 'line_name', width: 15, header: I18n.get('Line') },
		{ key: 'workstation_name', width: 15, header: I18n.get('Workstation') }
	];

	const hierarchyNames = {
		organization_name: organization?.name || '',
		line_name: searchParams.get('line_name') || '',
		sector_name: searchParams.get('sector_name') || '',
		company_name: searchParams.get('company_name') || '',
		workstation_name: searchParams.get('workstation_name') || ''
	};

	async function handleDownload() {
		try {
			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet('Risk History');

			worksheet.columns = columns;

			worksheet.getRow(1).eachCell((cell) => {
				cell.alignment = { vertical: 'middle', horizontal: 'center' };
				cell.font = { bold: true };
			});

			historyData?.forEach((row: any) => {
				const insertedRow = worksheet.addRow([
					I18n.get((searchParams.get('granularity') as any) || 'month'),
					row.date_group,
					row.low_risk,
					row.medium_risk,
					row.high_risk,
					hierarchyNames.organization_name,
					hierarchyNames.company_name,
					hierarchyNames.sector_name,
					hierarchyNames.line_name,
					hierarchyNames.workstation_name
				]);

				insertedRow.eachCell((cell) => {
					cell.alignment = { vertical: 'middle', horizontal: 'center' };
				});
			});

			worksheet.eachRow((row) => {
				row.height = 25;
			});

			const buffer = await workbook.xlsx.writeBuffer();
			const blob = new Blob([buffer], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			});

			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = `risk-history-${moment().format('YYYY-MM-DD')}.xlsx`;
			link.click();
			URL.revokeObjectURL(url);

			message.success(I18n.get('File sent for download'));
		} catch (error) {
			message.error(I18n.get('Failed to download file'));
		}
	}

	return (
		<ButtonWrapper>
			<CustomButton type="primary" icon={<DownloadOutlined size={20} />} onClick={handleDownload}>
				.CSV
			</CustomButton>
		</ButtonWrapper>
	);
}
