import { useQuery } from '@tanstack/react-query';
import { WorkstationService } from '@/infra/services/workstation';
import { QUERY_KEYS } from '@/utils/query-keys';

interface GetWorkstationsDTO {
	organization_id?: string;
	company_id?: string;
	sector_id?: string;
	line_id?: string;
}

const Service = WorkstationService.getInstance();

export const useGetWorkstations = (params: GetWorkstationsDTO) => {
	const { organization_id, company_id, sector_id, line_id } = params;
	return useQuery([QUERY_KEYS.GET_WORKSTATIONS, params], () => Service.index(params), {
		enabled: !!organization_id && !!company_id && !!sector_id && !!line_id
	});
};
