import React, { useState, useContext } from 'react';
import { I18n } from '@aws-amplify/core';
import { useQuery } from '@tanstack/react-query';
import { SearchOutlined } from '@ant-design/icons';
import { Row, Col, Form, Select, Button, Collapse } from 'antd';

import { useCompany } from '@/hooks/v1';
import { getSectors, getLines, getWorkstations } from '../services';
import { AnalyticsDashboardContext } from '../context';

const { Panel } = Collapse;

export const Filter = () => {
	const [form] = Form.useForm();
	const { organizationId, setFilter, resetFilter } = useContext(AnalyticsDashboardContext);

	const [companyId, setCompanyId] = useState('');
	const [sectorId, setSectorId] = useState('');
	const [lineId, setLineId] = useState('');

	const { companies } = useCompany({ organizationId });

	const sectors = useQuery(
		['sectors', { organizationId, companyId }],
		() => getSectors({ organizationId, companyId }),
		{
			enabled: !!organizationId && !!companyId
		}
	);

	const lines = useQuery(
		['lines', { organizationId, companyId, sectorId }],
		() => getLines({ organizationId, companyId, sectorId }),
		{
			enabled: !!organizationId && !!companyId && !!sectorId
		}
	);

	const workstations = useQuery(
		['lines', { organizationId, companyId, sectorId, lineId }],
		() => getWorkstations({ organizationId, companyId, sectorId, lineId }),
		{
			enabled: !!organizationId && !!companyId && !!sectorId && !!lineId
		}
	);

	function onSubmit(values) {
		const { company, sector, line, workstation } = values;
		setFilter({
			organizationId,
			...(company && { companyId: company }),
			...(sector && { sectorId: sector }),
			...(line && { lineId: line }),
			...(workstation && { workstationId: workstation })
		});
	}

	function onClear() {
		form.resetFields('');
		setCompanyId('');
		setSectorId('');
		setLineId('');
		resetFilter();
	}

	function resetFieldsForm(field) {
		const options = {
			company: ['sector', 'line', 'workstation'],
			sector: ['line', 'workstation'],
			line: ['workstation']
		};

		if (field === 'company') {
			setSectorId('');
			setLineId('');
		}

		if (field === 'sector') {
			setLineId('');
		}

		form.resetFields(options[field] || []);
	}

	return (
		<Col sm={24}>
			<Collapse defaultActiveKey={['1']}>
				<Panel header={I18n.get('Filters')} key="1">
					<Form form={form} layout="vertical" onFinish={onSubmit}>
						<Row gutter={[10, 0]} justify="space-around" align="middle">
							<Col xs={24} sm={6}>
								<Form.Item name="company" label={I18n.get('Industrial site')}>
									<Select
										showSearch
										onChange={(id) => {
											setCompanyId(id);
											resetFieldsForm('company');
										}}
										style={{ width: '100%' }}
										optionFilterProp="children"
										loading={companies.isLoading}
										placeholder={I18n.get('Select a industrial site')}
									>
										{companies.data?.map((item, key) => (
											<Select.Option key={key} value={item.id}>
												{item.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={6}>
								<Form.Item name="sector" label={I18n.get('Sector')}>
									<Select
										showSearch
										onChange={(id) => {
											setSectorId(id);
											resetFieldsForm('sector');
										}}
										disabled={!companyId}
										style={{ width: '100%' }}
										optionFilterProp="children"
										loading={sectors.isInitialLoading}
										placeholder={I18n.get('Select a sector')}
									>
										{sectors.data?.map((item, key) => (
											<Select.Option key={key} value={item.id}>
												{item.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={6}>
								<Form.Item name="line" label={I18n.get('Line')}>
									<Select
										showSearch
										onChange={(id) => {
											setLineId(id);
											resetFieldsForm('line');
										}}
										style={{ width: '100%' }}
										optionFilterProp="children"
										placeholder={I18n.get('Select a line')}
										loading={lines.isInitialLoading}
										disabled={!companyId || !sectorId}
									>
										{lines.data?.map((item, key) => (
											<Select.Option key={key} value={item.id}>
												{item.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={6}>
								<Form.Item name="workstation" label={I18n.get('Workstation')}>
									<Select
										showSearch
										style={{ width: '100%' }}
										optionFilterProp="children"
										loading={workstations.isInitialLoading}
										disabled={!companyId || !sectorId || !lineId}
										placeholder={I18n.get('Select a workstation')}
									>
										{workstations.data?.map((item, key) => (
											<Select.Option key={key} value={item.id}>
												{item.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={[10, 0]} justify="start" align="start">
							<Col sm={3}>
								<Button
									type="primary"
									htmlType="submit"
									style={{ width: '100%' }}
									icon={<SearchOutlined />}
								>
									{I18n.get('Search')}
								</Button>
							</Col>
							<Col sm={3}>
								<Button onClick={onClear} style={{ width: '100%' }}>
									{I18n.get('Clear')}
								</Button>
							</Col>
						</Row>
					</Form>
				</Panel>
			</Collapse>
		</Col>
	);
};
