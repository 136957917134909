import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useApplicationContext } from '@/context/application/index';
import { Content } from './Content';
import { Navigation } from './Navigation';
import { MosaicResultProvider } from './context';

export function Mosaic() {
	const history = useHistory();
	const location = useLocation();
	const { organization } = useApplicationContext();

	function handleOnClick(id: string, entity: string, name: string): void {
		const searchParams = new URLSearchParams(location.search);
		const existingValue = searchParams.get(entity);

		if (!entity || !id || existingValue === id) {
			return;
		}

		if (entity === 'workstation') {
			const organizationId = organization?.id;
			const sectorId = searchParams.get('sector');
			const companyId = searchParams.get('company');

			history.push({
				pathname: '/files',
				search: `?organization_id=${organizationId}&company_id=${companyId}&sector_id=${sectorId}&workstation_id=${id}`
			});
			return;
		}

		searchParams.set(entity, id);
		searchParams.set(`${entity}_name`, name);
		const path = `${location.pathname}?${searchParams.toString()}`;
		history.push(path);
	}

	return (
		<MosaicResultProvider>
			<Navigation />
			<Content handleOnClick={handleOnClick} />
		</MosaicResultProvider>
	);
}
