import { useQuery } from '@tanstack/react-query';
import { AppointmentService } from '@/infra/services/appointment';

const Service = AppointmentService.getInstance();

interface GetWorkMatricsParams {
	organization_id: string;
	start_date: string;
	end_date: string;
	group_by: string;
}

export const useGetWorkMatrics = (params: GetWorkMatricsParams) => {
	return useQuery(['lines', 'healthMatrix', params], () => Service.getWorkMetrics(params), {
		enabled: !!params.organization_id && !!params.start_date && !!params.end_date && !!params.group_by
	});
};
