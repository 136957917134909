import { Mapper } from './base-mapper';
import { DashboardComparative } from '@/core/domain/dashboard';
import { DashboardComparativeItemDTO } from '@/core/dto/dashboard';

export class DashboardComparativeMapper extends Mapper<DashboardComparative> {
	private readonly data: DashboardComparativeItemDTO[];

	constructor(data: DashboardComparativeItemDTO[]) {
		super();
		this.data = data;
	}

	toTransformItem(value: DashboardComparativeItemDTO): DashboardComparativeItemDTO {
		return {
			low: value.low,
			high: value.high,
			average: value.average,
			organization_name: value.organization_name,
			workstation_total: value.workstation_total,
			workstation_analyzed: value.workstation_analyzed,
			workstation_not_analyzed: value.workstation_not_analyzed
		};
	}

	toDomain(): DashboardComparative {
		return {
			rows: this.data.map(this.toTransformItem.bind(this))
		};
	}
}
