import React from 'react';
import { Bar } from 'react-chartjs-2';
import { I18n } from '@aws-amplify/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Tooltip,
	Legend,
	ChartOptions,
	ChartData
} from 'chart.js';

ChartJS.register(ChartDataLabels, CategoryScale, LinearScale, BarElement, Tooltip, Legend);

interface AssessmentData {
	low: number;
	high: number;
	average: number;
	organization_name: string;
	workstation_total: number;
	workstation_analyzed: number;
	workstation_not_analyzed: number;
}

interface ChartProps {
	data: AssessmentData[];
}

const options: ChartOptions<'bar'> = {
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		y: {
			grace: '5%'
		}
	},
	plugins: {
		legend: {
			position: 'bottom' as const,
			labels: {
				padding: 30,
				boxWidth: 40,
				boxHeight: 15,
				font: {
					size: 14
				}
			},
			maxHeight: 100,
			align: 'center',
			display: true
		},
		datalabels: {
			anchor: 'end' as const,
			align: 'top' as const,
			formatter: Math.round,
			font: {
				weight: 'bold'
			}
		}
	}
};

export function Chart({ data }: ChartProps): JSX.Element {
	const datasets: ChartData<'bar'> = {
		labels: data?.map((item) => item.organization_name),
		datasets: [
			{
				label: I18n.get('Workstations total'),
				data: data?.map((item) => item.workstation_total),
				borderWidth: 1,
				maxBarThickness: 40,
				borderColor: 'rgba(21, 39, 112, 1)',
				backgroundColor: 'rgba(21, 39, 112, 0.7)'
			},
			{
				label: I18n.get('Workstation not analyzed'),
				data: data?.map((item) => item.workstation_not_analyzed),
				borderWidth: 1,
				maxBarThickness: 40,
				borderColor: 'rgba(45, 78, 212, 1)',
				backgroundColor: 'rgba(45, 78, 212, 0.7)'
			},
			{
				label: I18n.get('Analyzed workstations'),
				data: data?.map((item) => item.workstation_analyzed),
				borderWidth: 1,
				maxBarThickness: 40,
				borderColor: 'rgba(156, 181, 250, 1)',
				backgroundColor: 'rgba(156, 181, 250, 0.7)'
			},
			{
				label: I18n.get('Low'),
				data: data?.map((item) => item.low),
				borderWidth: 1,
				maxBarThickness: 40,
				borderColor: 'rgba(44, 200, 82, 1)',
				backgroundColor: 'rgba(44, 200, 82, 0.7)'
			},
			{
				label: I18n.get('Average'),
				data: data?.map((item) => item.average),
				borderWidth: 1,
				maxBarThickness: 40,
				borderColor: 'rgba(248, 214, 39, 1)',
				backgroundColor: 'rgba(248, 214, 39, 0.7)'
			},
			{
				label: I18n.get('High'),
				data: data?.map((item) => item.high),
				borderWidth: 1,
				maxBarThickness: 40,
				borderColor: 'rgba(231, 65, 80, 1)',
				backgroundColor: 'rgba(231, 65, 80, 0.7)'
			}
		]
	};

	return <Bar options={options} data={datasets} height={'450px'} />;
}
