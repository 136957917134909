import styled from 'styled-components';
import { Input, Select } from 'antd';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px;
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	height: 80%;

	@media (max-width: 1600px) {
		height: 100%;
	}
`;

export const Title = styled.h3`
	font-size: 16px;
	color: #1a1a1a;
	margin-bottom: 24px;
	font-weight: 500;
`;

export const FilterGroup = styled.div`
	margin-bottom: 1.5rem;

	&:last-child {
		margin-bottom: 0;
	}
`;

export const Label = styled.label`
	display: block;
	font-size: 14px;
	color: #666;
	margin-bottom: 8px;
`;

export const CustomInput = styled(Input)`
	border-radius: 6px !important;
`;

export const CustomSelect = styled(Select).attrs({
	virtual: false
})`
	width: 100%;
	border-radius: 6px !important;

	.ant-select-selector {
		height: 40px !important;
		padding: 0 12px !important;
		border: 1px solid #e0e0e0;
		border-radius: 6px !important;
		background: #fff;
		font-size: 14px;
		color: #1a1a1a;
		cursor: pointer;
		outline: none;
		display: flex;
		align-items: center;

		.ant-select-selection-search {
			display: flex;
			align-items: center;

			input {
				height: 38px;
			}
		}

		.ant-select-selection-placeholder {
			display: flex;
			align-items: center;
			color: rgba(0, 0, 0, 0.25);
		}

		.ant-select-selection-item {
			display: flex;
			align-items: center;
		}

		&:focus {
			border-color: #1890ff;
		}

		&:disabled {
			background: #f5f5f5;
			cursor: not-allowed;
		}
	}
`;
