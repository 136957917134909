import React from 'react';
import { Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';

import { Title } from '@/components/Typography';
import { useApplicationContext } from '@/context/application/index';
import { useGetComparative } from '@/views/Dashboard/hooks/useGetComparative';

import { Chart } from './Chart';
import { Container } from './styles';
import { SkeletonChart } from '../HistoryChart/Skeleton';

export function AssessmentsChart() {
	const location = useLocation();
	const { organization } = useApplicationContext();
	const searchParams = new URLSearchParams(location.search);

	const { data, isLoading } = useGetComparative({
		organizationId: organization?.id,
		lineId: searchParams.get('line') || undefined,
		filter: searchParams.get('filter') || undefined,
		sectorId: searchParams.get('sector') || undefined,
		companyId: searchParams.get('company') || undefined
	});

	return (
		<Container>
			<Row gutter={[30, 0]}>
				<Col span={24}>
					<Title level={4}>Total jobs and risk assessments</Title>
				</Col>
				<Col span={24}>{isLoading ? <SkeletonChart /> : <Chart data={data || []} />}</Col>
			</Row>
		</Container>
	);
}
