import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { CompanyService } from '@/infra/services/company';

export interface GetCompaniesWorstScoreParams {
	organization_id: string;
	company_id?: string;
	filter?: string;
	offset: number;
	limit: number;
}

const Service = CompanyService.getInstance();

export const useGetCompaniesWorstScore = (params: GetCompaniesWorstScoreParams) => {
	const { organization_id, company_id, limit, offset, filter } = params;
	return useQuery(
		[QUERY_KEYS.GET_COMPANIES_WORST_SCORE, { organization_id, limit, offset, filter }],
		() => Service.findAllWorstScore({ organization_id, limit, offset, filter }),
		{
			enabled: !!organization_id && !company_id,
			retry: 1
		}
	);
};
