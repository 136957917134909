import React, { useEffect } from 'react';
import { Grid } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory, useLocation } from 'react-router-dom';

import { getGridSize } from '../helpers';
import { GridSkeleton } from './GridSkeleton';
import { MosaicComponent } from './MosaicComponent';
import { useMosaicDashboardContext } from '../context';
import { PaginationCustom } from '@/components/ui/Pagination';
import { useGetLinesWorstScore } from '@/views/Dashboard/hooks/useGetLinesWorstScore';
import { useGetSectorsWorstScore } from '@/views/Dashboard/hooks/useGetSectorsWorstScore';
import { useGetCompaniesWorstScore } from '@/views/Dashboard/hooks/useGetCompaniesWorstScore';
import { useGetWorkstationsWorstScores } from '@/views/Dashboard/hooks/useGetWorkstationsWorstScore';
import { handleBackClick } from '../Navigation/navigation-helpers';
import { getEntityNotFoundMessage } from './error-helpers';
import { Legend } from './Legend';
import * as S from '../styles';

const { useBreakpoint } = Grid;

interface ContentProps {
	handleOnClick: (id: string, entity: string, name: string) => void;
}

export function Content({ handleOnClick }: Readonly<ContentProps>) {
	const history = useHistory();
	const location = useLocation();
	const breakpoint = useBreakpoint();
	const { params, handleSetErrorWarning } = useMosaicDashboardContext();

	const {
		isFetching: isFetchingCompanies,
		isLoading: isLoadingCompanies,
		isError: isErrorCompanies,
		data: dataCompanies
	} = useGetCompaniesWorstScore(params);

	const {
		isFetching: isFetchingSectors,
		isLoading: isLoadingSectors,
		isError: isErrorSectors,
		data: dataSectors
	} = useGetSectorsWorstScore(params);

	const {
		isFetching: isFetchingLines,
		isLoading: isLoadingLines,
		isError: isErrorLines,
		data: dataLines
	} = useGetLinesWorstScore(params);

	const {
		isFetching: isFetchingWorkstations,
		isLoading: isLoadingWorkstations,
		isError: isErrorWorkstations,
		data: dataWorkstations
	} = useGetWorkstationsWorstScores(params);

	const isFetching = isFetchingCompanies || isFetchingSectors || isFetchingLines || isFetchingWorkstations;
	const isLoading = isFetching && (isLoadingCompanies || isLoadingSectors || isLoadingLines || isLoadingWorkstations);
	const data = dataWorkstations || dataLines || dataSectors || dataCompanies;
	const isError = isErrorCompanies || isErrorSectors || isErrorLines || isErrorWorkstations;

	useEffect(() => {
		if (isError) {
			handleSetErrorWarning(I18n.get('Unable to load data'), 'error');
			setTimeout(() => {
				handleBackClick(location, history);
			}, 1000);
		}

		if (!isError && data?.rows?.length === 0) {
			const message = getEntityNotFoundMessage(location);
			handleSetErrorWarning(message, 'warning');
			setTimeout(() => {
				handleBackClick(location, history);
			}, 1000);
		}
	}, [data?.rows, isError, handleSetErrorWarning, history, location]);

	const N = data?.rows?.length;
	const maxColumns = breakpoint.xl ? 4 : 3;
	const maxRows = breakpoint.xl ? 4 : 6;

	const { columns, rows } = getGridSize(N, maxColumns, maxRows);

	function handleOnPageChange(page: number): void {
		const searchParams = new URLSearchParams(location.search);
		searchParams.set('offset', String(page));
		const url = `${location.pathname}?${searchParams.toString()}`;
		history.push(url);
	}

	if (isLoading) {
		return (
			<div>
				<S.ContentWrapper>
					<GridSkeleton />
					<Legend />
				</S.ContentWrapper>
				<PaginationCustom
					loading={isLoading}
					maxPage={params.limit}
					hasItemPerPage={false}
					total={data?.total || 0}
					page={params.offset || 1}
					setPage={handleOnPageChange}
				/>
			</div>
		);
	}

	return (
		<div>
			<S.ContentWrapper>
				<S.MosaicGrid columns={columns} rows={rows}>
					{data?.rows?.map((item: any) => (
						<MosaicComponent key={item.id} item={item} entity={data.entity} onClick={handleOnClick} />
					))}
				</S.MosaicGrid>
				<Legend />
			</S.ContentWrapper>
			<PaginationCustom
				loading={isLoading}
				maxPage={params.limit}
				hasItemPerPage={false}
				total={data?.total || 0}
				page={params.offset || 1}
				setPage={handleOnPageChange}
			/>
		</div>
	);
}
