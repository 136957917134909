import * as React from 'react';

interface PwdProps extends React.SVGProps<SVGElement> {
	$count?: number;
}

export function Pwd({ $count, ...props }: PwdProps) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="50"
			height="24"
			fill="none"
			viewBox="0 0 50 24"
			{...props}
			className={`count-${$count}`}
			ref={undefined}
		>
			<rect width="50" height="24" fill="#2D4ED4" rx="3"></rect>
			{$count !== undefined && (
				<text x="25" y="16" fill="white" textAnchor="middle" fontSize="14" fontWeight={600}>
					{$count}
				</text>
			)}
		</svg>
	);
}
