import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { SectorService } from '@/infra/services/sector';

export interface GetSectorsWorstScoreParams {
	organization_id: string;
	company_id?: string;
	sector_id?: string;
	limit: number;
	offset: number;
	filter?: string;
}

const Service = SectorService.getInstance();

export const useGetSectorsWorstScore = (params: GetSectorsWorstScoreParams) => {
	const { company_id, organization_id, limit, offset, sector_id, filter } = params;
	return useQuery(
		[
			QUERY_KEYS.GET_SECTORS_WORST_SCORE,
			{
				organization_id,
				company_id,
				limit,
				offset,
				filter
			}
		],
		() =>
			Service.findAllWorstScore({
				organization_id,
				company_id: company_id!,
				limit,
				offset,
				filter
			}),
		{
			enabled: !!organization_id && !!company_id && !sector_id,
			retry: 1
		}
	);
};
