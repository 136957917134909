import React, { useState, useEffect } from 'react';
import { Row } from 'antd';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getElementAtEvent } from 'react-chartjs-2';

import Api from '@/services/api';
import { Header } from './Header';
import { CSV } from '@/utils/csv';
import { LineList } from './LineList';
import { MatrixChart } from './MatrixChart';
import { LineDetails } from './LineDetails';
import { HealthMatrixContext } from './context';
import { useGetWorkMatrics } from '@/hooks/useGetWorkMatrics';

export function HealthMatrix() {
	const [selectedLine, setSelectedLine] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const organizationId = useSelector(({ organization }) => organization.organization?.id);

	async function getLineHierarchy({ organizationId, lineId }) {
		if (!organizationId || !lineId) return;
		let url = `/line/hierarchy/${organizationId}/${lineId}`;
		const { data } = await Api.get(url);
		return data;
	}

	const linesParams = {
		organization_id: organizationId,
		start_date: startDate,
		end_date: endDate,
		group_by: 'line'
	};

	const lineHierarchyParams = {
		organizationId,
		lineId: selectedLine?.id
	};

	const lines = useGetWorkMatrics(linesParams);

	const lineHierarchy = useQuery(
		['lines', 'lineHierarchy', lineHierarchyParams],
		() => getLineHierarchy(lineHierarchyParams),
		{ enabled: !!selectedLine }
	);

	const selectedLineSector = {
		id: lineHierarchy.data?.sector?.id,
		name: lineHierarchy.data?.sector?.name
	};

	const selectedLineCompany = {
		id: lineHierarchy.data?.sector?.company?.id,
		name: lineHierarchy.data?.sector?.company?.name
	};

	function selectBarOnClick(e, ref, data) {
		const [meta] = getElementAtEvent(ref, e);
		if (!meta) return;
		const [selected] = data.filter((x, i) => i === meta.index);
		setSelectedLine(selected);
	}

	function updateSelectedLine(updatedLines) {
		if (!selectedLine || !updatedLines) return;

		const updatedSelectedLine = updatedLines.find((line) => line.id === selectedLine.id);
		setSelectedLine(updatedSelectedLine);
	}

	useEffect(() => {
		if (!startDate || !endDate) {
			setDefaultDate();
		}
	}, [startDate, endDate]);

	useEffect(() => {
		updateSelectedLine(lines.data);
	}, [lines.data]);

	function handleOnFilterDate(date) {
		if (date) {
			const [start, end] = date;
			const start_date = moment(start).startOf('month').toDate();
			const end_date = moment(end).endOf('month').toDate();

			setStartDate(start_date);
			setEndDate(end_date);
		} else {
			setDefaultDate();
		}
	}

	function setDefaultDate() {
		const today = moment();
		const isFirstSemester = today.month() < 6;

		const start_date = moment()
			.month(isFirstSemester ? 0 : 6)
			.startOf('month')
			.toDate();
		const end_date = moment()
			.month(isFirstSemester ? 5 : 11)
			.endOf('month')
			.toDate();

		setStartDate(start_date);
		setEndDate(end_date);
	}

	function handleExportCSV() {
		const rows = lines.data?.map((value) => ({
			Line: value.name,
			Score: value.score,
			'Ergonomic grade': value.global_grade,
			'Medical certificates': value.medical_certificate,
			'Complaints with nexus': value.complaint_with_nexus,
			'Complaints without nexus': value.complaint_without_nexus
		}));

		const csv = new CSV();
		csv.download(rows, `kinebot_${I18n.get('health_matrix')}-${moment().format('DD/MM/YYYY')}`);
	}

	function handleOnClearFilter() {
		setSelectedLine('');
	}

	const context = {
		lines,
		selectedLine,
		selectedLineSector,
		selectedLineCompany,
		lineHierarchy,
		startDate,
		endDate,
		selectBarOnClick
	};

	return (
		<HealthMatrixContext.Provider value={context}>
			<Row gutter={[0, 8]}>
				<Header onChangeDate={handleOnFilterDate} onClick={handleExportCSV} />
				{!lines.isLoading && lines.data && (
					<MatrixChart hasSelection={selectedLine} onClearFilter={handleOnClearFilter} />
				)}
				{selectedLine ? <LineDetails /> : <LineList />}
			</Row>
		</HealthMatrixContext.Provider>
	);
}
