import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { SectorService } from '@/infra/services/sector';

interface GetSectorsDTO {
	organization_id?: string;
	company_id?: string;
}

const Service = SectorService.getInstance();

export const useGetSectors = (params: GetSectorsDTO) => {
	return useQuery([QUERY_KEYS.GET_SECTORS, params], () => Service.index(params), {
		enabled: !!params.organization_id && !!params.company_id
	});
};
