import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { DashboardService } from '@/infra/services/dashboard';

export interface GetComparativeParams {
	organizationId?: string;
	companyId?: string;
	sectorId?: string;
	lineId?: string;
	filter?: string | null;
}

const Service = DashboardService.getInstance();

export const useGetComparative = (params: GetComparativeParams) => {
	const { organizationId, companyId, sectorId, lineId, filter } = params;

	return useQuery(
		[QUERY_KEYS.GET_COMPARATIVE, params],
		async () => {
			if (!organizationId) return [];
			const data = await Service.getComparative({
				organization_id: organizationId,
				company_id: companyId,
				sector_id: sectorId,
				line_id: lineId,
				filter: filter || undefined
			});
			return data.rows;
		},
		{
			enabled: !!organizationId,
			retry: 1,
			staleTime: 5 * 60 * 1000
		}
	);
};
