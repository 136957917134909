export const RISK_COLORS = {
	UNDEFINED_RISK: '#B3B3B3',
	LOW_RISK: '#2CC852',
	MEDIUM_RISK: '#F8D627',
	HIGH_RISK: '#E74150'
};

export const RISK_COLORS_INDEX = [
	RISK_COLORS.UNDEFINED_RISK,
	RISK_COLORS.HIGH_RISK,
	RISK_COLORS.MEDIUM_RISK,
	RISK_COLORS.LOW_RISK
];
