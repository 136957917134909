export const FORM_KEYS = {
	REFERENCE_PERIOD: 'reference_period',
	GRANULARITY: 'granularity'
};

export const GRANULARITY = {
	DAY: 'day',
	WEEK: 'week',
	YEAR: 'year',
	MONTH: 'month',
	QUARTER: 'quarter',
	SEMESTER: 'semester'
};
