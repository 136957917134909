import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { LineService } from '@/infra/services/line';

interface GetLinesDTO {
	organization_id?: string;
	company_id?: string;
	sector_id?: string;
}

const Service = LineService.getInstance();

export const useGetLines = (params: GetLinesDTO) => {
	return useQuery([QUERY_KEYS.GET_LINES, params], () => Service.index(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.sector_id
	});
};
